<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" xl="3">
          <v-autocomplete
            label="Invoice"
            outlined
            flat
            dense
            background-color="white"
            :items="invoices"
            item-text="invoiceNumber"
            v-model="invoice"
            return-object
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" xl="3">
          <v-btn color="success" @click="addToTable" class="mr-2"
            >Add
            <v-icon>
              mdi-plus
            </v-icon>
          </v-btn>
          <v-btn color="primary" @click="showCancelInvoice"
            >Show All Cancel Invoice
            <v-icon>
              mdi-eye
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-data-table :headers="headers" :items="items" dense>
        <template v-slot:item.no="{ index }">
          {{ index + 1 }}
        </template>
        <template v-slot:item.remark="{ item }">
          <v-textarea v-model="item.remark" outlined flat rows="3"></v-textarea>
        </template>
        <template v-slot:item.cancelDate="{ item }">
          <!-- <v-edit-dialog large>
            <div>{{ item.cancelDate }}</div>
            <template v-slot:input>
              <div class="mt-4 text-h6">
                Update Cancel Date
              </div>
              
            </template>
          </v-edit-dialog> -->
          <v-menu
            v-model="menuDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                flat
                dense
                background-color="white"
                v-model="item.cancelDate"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="item.cancelDate" @input="menuDate = false"></v-date-picker>
          </v-menu>
        </template>
        <template v-slot:item.totalAmountAfterTax="{ item }">
          {{ formatPrice(item.totalAmountAfterTax) }}
        </template>
        <template v-slot:item.action="{ index, item }">
          <v-btn color="primary" @click.stop="openDialog(item)" class="mr-2">Cancel</v-btn>
          <v-btn color="error" @click.stop="deleteItem(index)">Delete Item</v-btn>
        </template>
      </v-data-table>
    </v-col>

    <confirm-cancel-invoice
      :dialog="dialog"
      :invoiceNumber="form.invoiceNumber"
      @close="close"
      @save="cancel"
    ></confirm-cancel-invoice>
  </v-row>
</template>

<script>
import ConfirmCancelInvoice from "./ConfirmCancelInvoice.vue";

export default {
  name: "cancel-invoice",
  components: {
    ConfirmCancelInvoice,
  },
  data() {
    return {
      invoices: [],
      items: [],
      invoice: {},
      menuDate: false,
      form: {},
      dialog: false,
      headers: [
        {
          id: 1,
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 2,
          text: "Inv #",
          value: "invoiceNumber",
          sortable: false,
          align: "center",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 3,
          text: "Remark",
          value: "remark",
          sortable: false,
          align: "center",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 4,
          text: "Tanggal Batal",
          value: "cancelDate",
          sortable: false,
          align: "center",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 4,
          text: "User",
          value: "customerName",
          sortable: false,
          align: "center",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 5,
          text: "Inv Date",
          value: "invoiceDate",
          sortable: false,
          align: "center",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 6,
          text: "Total",
          value: "totalAmountAfterTax",
          sortable: false,
          align: "center",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 7,
          text: "Action",
          value: "action",
          sortable: false,
          align: "center",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
      ],
    };
  },
  methods: {
    async getCancelInvoice() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("saleInvoice/getCancelInvoice")
        .then(response => {
          this.invoices = response.data;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    addToTable() {
      if (!this.invoice) {
        this.$store.dispatch("toast", {
          type: "error",
          message: "Mohon untuk pilih invoice yang akan di tambah",
        });
      } else {
        if (this.items.findIndex(x => x === this.invoice) > -1) {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Invoice sudah di tambah ke dalam tabel",
          });
        } else {
          this.items.push(this.invoice);
        }
      }
    },
    showCancelInvoice() {},
    openDialog(item) {
      this.form = item;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    async cancel(params) {
      this.form.typeAction = params.type;
      this.$store.commit("SET_LOADER", true);
      if (this.form.type === "DP") {
        await this.$store
          .dispatch("saleDownPayment/cancelInvoiceV2", { form: this.form, cancel: params.cancel })
          .then(() => {
            this.$store.commit("SET_LOADER", false);
            window.location.reload();
          })
          .catch(() => {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
            });
            this.$store.commit("SET_LOADER", false);
          });
      } else {
        await this.$store
          .dispatch("saleInvoice/cancelInvoiceV2", { form: this.form, cancel: params.cancel })
          .then(() => {
            this.$store.commit("SET_LOADER", false);
            window.location.reload();
          })
          .catch(() => {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
            });
            this.$store.commit("SET_LOADER", false);
          });
      }
    },
    deleteItem(index) {
      this.items.splice(index, 1);
    },
    formatPrice(value) {
      let val = (value / 1).toFixed().replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
  mounted() {
    this.getCancelInvoice();
  },
};
</script>

<style></style>
