<template>
  <v-dialog v-model="dialog" max-width="800" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Confirm Cancel Invoice</span>
      </v-card-title>
      <v-card-text>
        <div class="red--text text-uppercase font-weight-bold">PERHATIAN !!</div>
        <div>
          Apakah anda yakin akan melakukan pembatalan / penghapusan
          <span class="font-weight-bold">Invoice # {{ invoiceNumber }}</span
          >?
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="cancel" class="mr-2">
          Close
        </v-btn>
        <v-btn color="primary" @click="save('nothing', true)">
          Pembatalan
        </v-btn>
        <v-btn color="primary" @click="save('invoice', false)">
          Cancel invoice dan Bisa digunakan Lagi
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "confirm-cancel-invoice",
  props: {
    dialog: Boolean,
    invoiceNumber: Number,
  },
  methods: {
    save(type, cancel) {
      this.$emit("save", { type: type, cancel: cancel });
      this.cancel();
    },
    cancel() {
      this.$emit("close");
    },
  },
};
</script>

<style></style>
